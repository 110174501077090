/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useRef, useState } from "react"
import cls from "./Hero.module.scss"
import Black from "@/HERO/black.webp"
import Back from "@/HERO/backMBL.webp"
import ComingSoon from "@/HERO/coming.svg"
import ComingSoonMbl from "@/HERO/comingmbl.svg"
import Star from "@/HERO/star.svg"
import Sparkle from "@/HERO/sparkle.svg"
import Label from "@/HERO/label.svg"

import { Button, ButtonColor, ButtonStyle } from "~/components/shared/Button/Button"
import { toElement } from "~/helpers/helpers"
import { ampli, track } from "~/helpers/amplitude"

const Hero = forwardRef((props, ref) => {
    const revealElements = useRef(null)
    useEffect(() => {
        const elements = revealElements.current.querySelectorAll("[data-reveal]")
        const options = {
            threshold: 0.1
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("animated")
                    observer.unobserve(entry.target)
                }
            })
        }, options)
        elements.forEach((el) => observer.observe(el))

        return () => observer.disconnect()
    }, [])
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        const img = new Image()
        img.src = Black
        img.onload = () => {
            setIsLoaded(true)
        }
    }, [])
    const { NFTRef } = props
    return (
        <section className={cls.hero} ref={ref} data-scroll-place-id="meet toon">
            <img src={Back} className={`${cls.back} ${isLoaded ? cls.loaded : ""}`} data-absolute alt="orb" />
            <div className={cls.test} />
            <div className={cls.test2} />
            <div className={`${cls.coming} ${isLoaded ? cls.loaded : ""}`} data-absolute>
                <img src={ComingSoon} alt="coming soon" />
            </div>
            <div className={`${cls.comingmbl} ${isLoaded ? cls.loaded : ""}`} data-absolute>
                <img src={ComingSoonMbl} alt="coming soon" />
            </div>
            <div className={cls.black} data-absolute>
                <img src={Black} alt="black void" />
            </div>
            <div data-content className={cls.content} ref={revealElements}>
                <img
                    src={Sparkle}
                    alt="sparkle"
                    className={cls.sparkle}
                    data-absolute
                    width="16px"
                    height="16px"
                    data-object
                    data-reveal="2"
                />
                <img
                    src={Star}
                    alt="star"
                    className={cls.star}
                    data-absolute
                    width="24px"
                    height="24px"
                    data-object
                    data-reveal="4"
                />
                <img
                    src={Star}
                    alt="star"
                    className={cls.star2}
                    data-absolute
                    width="24px"
                    height="24px"
                    data-object
                    data-reveal="6"
                />
                <div className={cls.header}>
                    <h1 data-reveal="1">Meet ToON</h1>
                    <img
                        src={Label}
                        alt="$TOON coming soon"
                        className={cls.label}
                        data-absolute
                        width="224px"
                        height="48px"
                        data-reveal="4"
                        onClick={() => {
                            track(ampli.heroLabel)
                            toElement(NFTRef)
                        }}
                    />
                </div>
                <h5 data-reveal="2">
                    <b>ToON</b> ecosystem — L2, a native token, apps, and dApps — aims to accelerate mass adoption of
                    crypto. We build user-friendly products for Web2 and Web3.
                </h5>

                <div className={cls.button} data-reveal="4">
                    <Button
                        color={ButtonColor.pink}
                        style={ButtonStyle.primary}
                        onClick={() => {
                            track(ampli.join)
                            toElement(NFTRef)
                        }}
                    >
                        Join Us
                    </Button>
                </div>
            </div>
        </section>
    )
})

export default Hero
