import React, { useCallback } from "react"
import { toast } from "react-toastify"
import Error from "@/UI/icons/error.svg"
import Wallet from "@/UI/icons/wallet.webp"

import { CHECK_WALLET, PURCHASE_ERRORS } from "../texts"

const { 4001: cancelledMessage } = PURCHASE_ERRORS
const { 32000: fundsMessage } = PURCHASE_ERRORS
const generalMessage = PURCHASE_ERRORS.general

function getImage(chain) {
    switch (chain.id) {
        case 1:
            return "./assets/UI/icons/1.webp"
        case 56:
            return "./assets/UI/icons/56.webp"
        case 137:
            return "./assets/UI/icons/137.webp"
        case 8453:
            return "./assets/UI/icons/8453.webp"
        case 10:
            return "./assets/UI/icons/10.webp"
        /* case 42161:
			return "./assets/UI/icons/42161.webp" */
        default:
            return "./assets/UI/icons/default.webp"
    }
}

export function useToast() {
    const checkWallet = useCallback(() => {
        toast.info(CHECK_WALLET, {
            icon: () => <img src={Wallet} className="toastIcon" alt="icon" />,
            toastId: "check",
            autoClose: false,
            delay: 500,
            className: "checkWalletToast"
        })
    }, [])

    const chooseOption = useCallback(() => {
        toast.info("Please enter value or click the checkbox", {
            icon: () => <img src={Error} className="toastIcon" alt="icon" />,
            toastId: "choose",
            position: "top-center"
        })
    }, [])

    const mintError = useCallback((error) => {
        let message
        if (
            error.message.includes("cancelled") ||
            error.message.includes("canceled") ||
            error.message.includes("rejected")
        ) {
            message = cancelledMessage
        } else if (error.message.includes("funds")) {
            message = fundsMessage
        } else {
            message = generalMessage
        }

        toast.error(message, {
            icon: () => <img src={Error} className="toastIcon" alt="icon" />,
            toastId: "error"
        })
    }, [])

    const chainSwitch = useCallback((chain) => {
        const imageSrc = getImage(chain)
        toast.success(
            () => (
                <>
                    Successfully connected to <b>{chain.name}</b> wallet!
                </>
            ),
            {
                icon: () => <img src={imageSrc} className="toastIcon" alt="icon" />,
                toastId: chain.name
            }
        )
    }, [])

    const dismissAll = () => toast.dismiss()

    return {
        mintError,
        chainSwitch,
        dismissAll,
        checkWallet,
        chooseOption
    }
}
