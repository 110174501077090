import React, { lazy } from "react"
import { Button } from "../Button/Button.jsx"

import cls from "./Error.module.scss"
import ErrorImage from "@/error/error.webp"
import { HeaderSimple } from "../Header/HeaderSimple.jsx"

const Footer = lazy(() => import("~/components/shared/Footer/Footer.jsx"))

export default function Error() {
    const refreshPage = () => {
        window.location.reload(false)
    }
    return (
        <>
            <div id="content-wrap">
                <HeaderSimple />
                <div className={cls.Error}>
                    <img src={ErrorImage} className={cls.main} alt="Error" />
                    <h2>Something went wrong...</h2>
                    <Button onClick={refreshPage}>Reload</Button>
                </div>
            </div>
            <footer className="footer" id="footer">
                <Footer />
            </footer>
        </>
    )
}
