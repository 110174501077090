export function makeClientToken() {
    let platformSegment = "u"
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android/i.test(userAgent)) {
        platformSegment = "a"
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        platformSegment = "i"
    }

    let result = ""
    const map = "abcdef0123456789"
    for (let i = 0; i < 32; i++) {
        result += map.charAt(Math.floor(Math.random() * map.length))
    }

    return ["b", platformSegment, result].join(":")
}
