import { sepolia } from "wagmi"
import { base, optimism } from "viem/chains"
import { makeClientToken } from "./tools/makeTokem"
import { queryStringToObject } from "./tools/url"

const query = queryStringToObject(window.location.search.substring(1))
const isDebug = !!query.debug
const isProduction = true
const clientToken = window.localStorage["tooncoin:client_token"] || makeClientToken()

window.localStorage["tooncoin:client_token"] = clientToken
window.localStorage["tooncoin:first_visit_at"] = window.localStorage["tooncoin:first_visit_at"] || Date.now()
let injectedProvider = false

if (typeof window.ethereum !== "undefined") {
    injectedProvider = true
}

export const priceJSON = {
    ethBalance: 277,
    ethPrice: 282334000000,
    totalSupply: "1117945527006896347249698",
    price: 1.448,
    address: "0xE8078B5198E572Be8D8D412511d48b7D0f5E9a1c",
    updatedAt: 1708086764312,
    ethRate: 2840.36,
    bnbRate: 361.5,
    maticRate: 0.9324,
    opRate: 3.808,
    baseRate: 0.8606
}

const isMetaMask = injectedProvider ? window.ethereum.isMetaMask : false
export const supportedChains = [1, 56, 137, 8453, 10]
// export const supportedChains = [1, 56, 137, 42161, 11155111]

export const mainconfig = {
    query,
    isDebug,
    isProduction,
    isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    isAndroid: /android/i.test(navigator.userAgent),
    isMobile: /iphone|ipad|android/i.test(window.navigator.userAgent),
    isMetaMaskOld: /metamask/i.test(window.navigator.userAgent), // testing whether the user's browser is running the MetaMask extension
    isMetaMask,
    clientToken,
    locale: query.locale || query.lang || window.navigator.language || undefined,
    ethAmountQueryKey: "eth_amount",
    services: {
        walletconnect: {
            key: "e02c07c4e6a5e7d7cb2c7e53b04c3990"
        },
        infura: {
            oldkey: "4c36a49cef8548e6b5707243c5ba36f3",
            key: "9dac313413d04fa2945eb7ffeec43b03"
        },
        alchemy: {
            key: "wg6wp8N_jgPqBLyx94PxLRrNLVer2Elb"
        },
        amplitude: {
            // EARLY ACCESS AMPLITUDE ORG
            key: "2acf973b95c5ee353d8e6f90f2b76cf9"
        }
    }
}

export const NFTcontracts = {
    11155111: "0x366499256bb1b692B94adab526f3cc9DA30F1A8d",
    1: "0x49cC7de889C1e4bDc1b4156B882cA5c76C668987",
    137: "0xA42ccA468fd07491824Ab121CB559f1B3791e92C",
    56: "0xFA17c9e4f5Ec1A62d032731fE0a9529D7B851BE6",
    10: "0x7A34e3e17Cf4cf18B3d9046eA0685e1b7dC32a2c",
    8453: "0xB6763731f5CB6836c6Ab2f82De3DE64292AdA234"
}

export const FreeAbi = [
    {
        name: "mint",
        type: "function",
        stateMutability: "payable",
        inputs: [
            {
                internalType: "uint256",
                name: "_mintAmount",
                type: "uint256"
            }
        ],
        outputs: []
    }
]

export const PayableAbi = [
    {
        name: "mintPayable",
        type: "function",
        stateMutability: "payable",
        inputs: [
            {
                internalType: "uint256",
                name: "_mintAmount",
                type: "uint256"
            }
        ],
        outputs: []
    }
]

const differentAbiChains = [sepolia.id, optimism.id, base.id]
export const getAbi = (chainId, option) => {
    if (differentAbiChains.includes(chainId) && option === "option1") {
        return PayableAbi
    }
    return FreeAbi
}

export const getFunctionName = (chainId, option) => {
    if (differentAbiChains.includes(chainId) && option === "option1") {
        return "mintPayable"
    }
    return "mint"
}
