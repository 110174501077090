import React from "react"
import { classNames } from "../../../tools/classNames"
import cls from "./Loader.module.scss"

export const LineLoaderColor = {
    blue: "0,102,255",
    orange: "247, 159, 31",
    default: "212, 212, 216"
}

export default function Loader() {
    return (
        <div className="lds-circle-loader">
            <div className="circle" />
        </div>
    )
}

export function TextLoader(props) {
    const { text, className, color } = props
    const mods = {
        [cls.text]: text
    }
    return (
        <p className={classNames(cls.textLoader, mods, [className])} style={{ color }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                    d="M19 9.99995C18.9999 11.9005 18.3981 13.7523 17.2809 15.2899C16.1637 16.8274 14.5885 17.9719 12.7809 18.5591C10.9733 19.1464 9.02619 19.1463 7.21864 18.559C5.41109 17.9716 3.83588 16.8271 2.71876 15.2895C1.60165 13.7518 0.999986 11.9 1 9.99945C1.00001 8.09886 1.60171 6.24706 2.71884 4.70945C3.83598 3.17184 5.4112 2.02736 7.21877 1.44003C9.02633 0.852698 10.9734 0.852672 12.781 1.43995"
                    stroke={color || "#3F3F46"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            {text && `${text}...`}
        </p>
    )
}

export function LineLoader({ color = LineLoaderColor.default, className }) {
    return (
        <div className={classNames(cls.LineLoader, {}, [className])}>
            <div className={cls.line} style={{ "--color": color }} />
        </div>
    )
}
